import { RowsChanged } from './events/RowsChanged';
import { EventAggregator } from 'aurelia-event-aggregator';

import { DialogService } from 'aurelia-dialog';
import { bindable, computedFrom, autoinject, PLATFORM, LogManager } from 'aurelia-framework';
import { ValidationController } from 'aurelia-validation';
import { Service } from '@feathersjs/feathers';

import { ActionService } from '../../services/actions/ActionService';
import { AlertService } from '../../services/util/Alert';

import { BaseModel } from '@wsb_dev/datafi-shared/lib/types/BaseModel';
import { ActionMessageData } from '@wsb_dev/datafi-shared/lib/types/ActionMessageData';
import { DefaultActionData } from '@wsb_dev/datafi-shared/lib/types/ActionTypes';

import { debounced } from '../../util/decorators/debounced';
import { getterThrottle } from '../../util/decorators/getterThrottle';
import { getFieldsFromRow } from './fields/getFieldsFromRow';
import { Field } from '@wsb_dev/datafi-shared/lib/types/Field';
import { createCustomEvent } from '../../util/events/createCustomEvent';

import { MDCDelete } from '../mdc-delete-dialog/mdc-delete-dialog';
import { subscribe } from '../../util/decorators/subscribe';
import { SurveyEdit } from '../surveys/survey-edit/survey-edit';
import { DatafiProAPI } from 'services/api/DatafiProAPI';

PLATFORM.moduleName('../mdc-delete-dialog/mdc-delete-dialog');

const log = LogManager.getLogger('dfp:table');

@autoinject
@subscribe({
    events: [
        { event: 'change', eventEmitter: 'service', fn: 'fetchRows' },
    ],
})
export class AdminTable {
    @bindable fields: Field[];

    @getterThrottle(200)
    @computedFrom('fields')
    get _fields(): Field[] {
        if (!this.fields) {
            return [];
        }

        return this.fields
            .filter((f) => typeof f.visibility === 'undefined' || f.visibility.list);
    }

    @bindable RowType: any;
    @bindable service: Service<any>;
    @bindable DialogViewModel: DialogService;
    @bindable controller: ValidationController;

    @bindable rows?: BaseModel[];
    @bindable selectedRows?: BaseModel[] = [];

    @bindable params = {};
    @bindable deleteParams = {};
    @bindable perPage = 25;
    @bindable page = 1;
    @bindable total = 0;
    @bindable idProp = 'id';

    @bindable busy: boolean;

    @bindable title: string;

    /**
     * Whether or not user can create items
     */
    @bindable canCreate: boolean;
    /**
     * Whether or not user can edit items
     */
    @bindable canEdit: boolean;
    /**
     * Whether or not user can delete items
     */
    @bindable canDelete: boolean;
    /**
     * Whether or not user has higher permissions
     */
    @bindable higherPermissions: boolean;
    /**
     * Actions that can be run from this table
     */
    @bindable actions?: DefaultActionData[];

    /**
     * Sorting configuration:
     *
     * @example
     * {
     *      fieldName: -1, // desc
     *      otherField: 1,
     * }
     */
    @bindable sort: Record<string, number> = {};

    constructor(
        private dialogService: DialogService,
        private element: Element,
        private alerts: AlertService,
        private actionService: ActionService,
        private ea: EventAggregator,
        private api: DatafiProAPI,
    ) { }

    @getterThrottle(100)
    @computedFrom('actions')
    get inlineActions(): DefaultActionData[] {
        return this.actions?.filter((a) => a.inline);
    }
    @getterThrottle(100)
    @computedFrom('total', 'perPage')
    get lastPage(): number {
        return this.total < this.perPage ? 1 : Math.ceil(this.total / this.perPage);
    }

    @getterThrottle(100)
    @computedFrom('page', 'perPage', 'total')
    get fromCount(): number {
        return (this.page - 1) * this.perPage + 1;
    }

    @getterThrottle(100)
    @computedFrom('page', 'perPage', 'total')
    get toCount(): number {
        const endCount = this.perPage * this.page;
        if (endCount > this.total) {
            return this.total;
        }
        return this.page * this.perPage;
    }

    attached(): void {
        this.refreshParams();
        this.fetchRows();
    }

    perPageChanged(val: number): void {
        this.refreshParams();
    }

    pageChanged(val: number): void {
        this.refreshParams();
    }

    paramsChanged(): void {
        this.fetchRows();
    }

    fieldsChanged(newFields?: Field[]): void {
        if (!newFields?.length) {
            return;
        }

        newFields.forEach((f) => {
            if (f.defaultSort) {
                this.sort[f.path] = f.defaultSort;
            }
        });

        this.refreshParams();
    }

    rowsChanged(rows: BaseModel[]): void {
        this.selectedRows = [];
        if (!this.fields?.length && rows?.length) {
            this.fields = getFieldsFromRow(rows[0]);
        }
        this.ea.publish(new RowsChanged(this.service?.path || this.service?.name, rows));
        this.element?.dispatchEvent(createCustomEvent('rows-changed', {
            target: rows,
        }));
    }

    refreshParams(): void {
        this.params = {
            ...this.params,
            $sort: this.sort,
            $limit: this.perPage,
            $skip: (this.page - 1) * this.perPage,
        };
    }

    @debounced(200)
    async fetchRows(): Promise<void> {
        if (!this.service) {
            return;
        }
        this.busy = true;
        return this.service.find({
            query: {
                ...this.params,
                $limit: this.perPage,
            },
        })
            .then((result) => {
                const rows = this.RowType ?
                    result.data.map((row) => new this.RowType(row)) :
                    result.data;
                Object.assign(this, {
                    total: result.total,
                    rows,
                });
            })
            .catch((e) => this.alerts.create({
                level: 'error',
                label: e.message,
                dismissable: true,
            }))
            .finally(() => this.busy = false);
    }

    handleSort(field: string, event?: MouseEvent): void {

        // if empty field is passed, reset the sort
        if (!field) {
            this.sort = {};
            return;
        }

        // if shift key is pressed, allow multiple sort
        // otherwise reset the current sort
        if (!event || !event.shiftKey) {
            Object.keys(this.sort).forEach((key) => {
                if (key !== field) {
                    this.sort[key] = undefined;
                }
            });
        }

        if (this.sort[field] === 1) {
            this.sort[field] = -1;
        } else {
            this.sort[field] = 1;
        }

        this.refreshParams();
    }

    handleNavigation(type: string): void {
        switch (type) {
        case 'next':
            if (this.page < this.lastPage) {
                this.page++;
            }
            break;
        case 'prev':
            if (this.page > 1) {
                this.page--;
            }
            break;
        case 'last':
            this.page = this.lastPage;
            break;
        case 'first':
            this.page = 1;
            break;
        }
    }

    actionAllowed(action: DefaultActionData, row: BaseModel) {
        return action.allowOnAll ? true :
            this.higherPermissions ? this.higherPermissions :
                this.api.auth.me.username === row.createdBy;
    }

    async handleAction(action: DefaultActionData, target?: BaseModel[]): Promise<DefaultActionData> {
        if (!target) {
            const actionTarget = this.selectedRows.length ? this.selectedRows : this.rows;
            if (action.edit && !this.actionAllowed(action, actionTarget[0])) {
                this.alerts.create({
                    level: 'error',
                    label: 'You do not have permission to edit this submission.',
                    dismissable: true,
                });
                return;
            }
            action.__pending = true;
            return this.actionService.create({
                ...action,
                target: actionTarget,
                viewModel: this,
            }).finally(() => {
                action.__pending = false;
            });
        }
        if (target) {
            target.forEach((t) => t.__pending = action);
            this.actionService.create({
                ...action,
                target,
                viewModel: this,
            }).finally(() => target.forEach((t) => t.__pending = null));
        }
    }

    toggle(row: BaseModel): void {
        let index;
        if (this.isSelected(row, this.selectedRows)) {
            for (let i = 0; i < this.selectedRows.length; i++) {
                if (this.selectedRows[i][this.idProp] === row[this.idProp]) {
                    index = i;
                    break;
                }
            }

            this.selectedRows.splice(index, 1);
        } else {
            this.selectedRows.push(row);
        }
        this.selectedRows = [...this.selectedRows];
    }

    isAllSelected(rows: BaseModel[], selectedRows: BaseModel[]): boolean {
        if (!rows) {
            return false;
        }
        for (const row of rows) {
            if (!this.isSelected(row, selectedRows)) {
                return false;
            }
        }
        return true;
    }

    isSelected(row: BaseModel, selectedRows: BaseModel[]): boolean {
        for (const selected of selectedRows) {
            if (selected[this.idProp] === row[this.idProp]) {
                return true;
            }
        }

        return false;
    }

    selectAll(): void {
        const allSelected = this.rows.every((r) => this.isSelected(r, this.selectedRows));

        if (allSelected) {
            const theseRows = this.rows.map((r) => r[this.idProp]);
            this.selectedRows = this.selectedRows.filter((r) => !theseRows.includes(r[this.idProp]));

        } else {
            this.rows.forEach((row) => {
                if (!this.isSelected(row, this.selectedRows)) {
                    this.selectedRows.push(row);
                }
            });
        }
        this.selectedRows = [...this.selectedRows];

    }

    clearSelected(): void {
        this.selectedRows = [];
    }

    create(): Promise<void> {
        return this.edit({} as BaseModel);
    }

    edit(item: BaseModel): Promise<void> {
        return this.dialogService.open({
            viewModel: this.DialogViewModel,
            model: { ...item },
        }).whenClosed((result) => {
            if (result.wasCancelled) {
                return;
            }
            if (item[this.idProp]) {
                this.rows = this.rows.map((row) => {
                    if (row[this.idProp] === item[this.idProp]) {
                        return {
                            ...row,
                            ...result.output,
                        };
                    }
                    return row;
                });
            } else {
                this.rows = [
                    ...this.rows,
                    new this.RowType(result.output),
                ];
            }
        });
    }

    delete(rows: BaseModel[]): Promise<void> {
        if (!rows.length) {
            return;
        }

        return this.dialogService.open({
            viewModel: MDCDelete,
            model: rows,
        }).whenClosed((result) => {
            if (result.wasCancelled) {
                return;
            } else {
                return Promise.all(rows.map((item) => {
                    return this.service.remove(item[this.idProp], { query: this.deleteParams });
                })).then(() => {
                    this.alerts.create({
                        label: `${this.title}(s) deleted`, dismissable: true,
                    } as ActionMessageData);
                    this.selectedRows = [];
                    this.refreshParams();
                })
                    .catch((e) => {
                        log.error(e);
                        this.alerts.create({
                            label: e,
                            level: 'error',
                            dismissable: true,
                        } as ActionMessageData);
                    });
            }
        });
    }

    switchProjects(row: BaseModel[]) {
        return this.dialogService.open({
            viewModel: SurveyEdit,
            model: { rows: row, fields: this.fields },
        }).whenClosed((result) => {
            if (result.wasCancelled) {
                return;
            } else {
                this.selectedRows = [];
                this.refreshParams();
            }
        });
    }
}
